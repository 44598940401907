
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticPropsResult } from 'next';
import ContainerGrid from 'src/components/container-grid';
import { TransientErrorMessage } from 'src/components/errors/transient-messages';
import HeadTags from 'src/components/head-tags';
import Page from 'src/components/page';
import useTranslate from 'src/hooks/use-translate';
import { useImpressionTracking } from 'src/tracking';
import { defaultNotFoundPageProps } from 'src/utilities/ssr-helpers';
function ErrorPage404(): JSX.Element {
    const t = useTranslate();
    // track each display of an error page in the analytics
    const { impressionObserverRef } = useImpressionTracking({ eventName: 'ErrorPageImpression', errorCode: 404 });
    return (<Page testId="error-page-404">
      <HeadTags title={t('page__404_title')} noindex/>
      <ContainerGrid>
        <div className="col-span-full my-10" ref={impressionObserverRef}>
          <TransientErrorMessage title={t('error_page_404__title')} message={t('error_page_404__message')} action="homepage"/>
        </div>
      </ContainerGrid>
    </Page>);
}
function getStaticProps(): GetStaticPropsResult<Record<string, never>> {
    return {
        props: {},
        // rebuild 404 page if cache is older default wait time, it helps with stale/new content
        revalidate: defaultNotFoundPageProps.revalidate
    };
}
export default ErrorPage404;

    async function __Next_Translate__getStaticProps__1950518973a__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1950518973a__ as getStaticProps }
  